import Web3 from "web3";
import { supportNetwork } from './network';
import { MAINNET_RPC } from "./constant";



export const getWeb3 = (chainId = "default") => {
  return new Web3(supportNetwork[chainId] ? supportNetwork[chainId].rpc : supportNetwork['default'].rpc);
}

export const getMainnetWeb3 = () => {
  return new Web3(MAINNET_RPC);
}
