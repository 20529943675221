import React, { useState } from 'react'
import { DEFAULT_CHAIN, contract, formatPrice } from '../hooks/constant';
import { useAccStats, useCommonStats } from '../stats/useStats';
import { toast } from 'react-toastify';
import { getContract } from '../hooks/contractHelper';
import dividendAbi from '../json/dividend.json';
import tokenAbi from '../json/token.json';
import { getWeb3 } from '../hooks/connectors';
import { ethers } from 'ethers';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { useAccount, useNetwork } from 'wagmi';




export default function Token() {
    const [updater, setUpdater] = useState(1);
    const accStats = useAccStats(updater);
    const stats = useCommonStats(updater);
    const { address } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [loading, setLoading] = useState({
        stake: false,
        unstake: false,
        claim: false
    });
    const [samount, setSamount] = useState(0);
    const [wamount, setWamount] = useState(0);
    const [error, setError] = useState({
        stake: "",
        unstake: ""
    })


    const handleClaim = async () => {
        setLoading({ ...loading, "claim": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].TOKEN_DIVIDEND_ADDRESS, signer);
                    let tx;
                    tx = await divContract.claim({ 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "claim": false });
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "claim": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "claim": false });
        }
    }

    const handleChangeAmount = (e, type) => {
        const patt = /^\d+\.{0,1}\d{0,4}$/;
        if (patt.test(e.target.value)) {
            if (type === 'stake') {
                setError({ ...error, "stake": "" })
                setSamount(e.target.value);
            }
            else {
                setError({ ...error, "unstake": "" })
                setWamount(e.target.value);
            }
        }
        else if (e.target.value === '' || e.target.value === 0) {
            if (type === 'stake') {
                setError({ ...error, "stake": "please enter valid amount." })
                setSamount(e.target.value);
            }
            else {
                setError({ ...error, "unstake": "please enter valid amount." })
                setWamount(e.target.value);
            }
        }
    }


    const handleStake = async () => {
        setLoading({ ...loading, "stake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                const patt = /^\d+\.{0,1}\d{0,4}$/;
                if (samount && patt.test(samount)) {
                    try {
                        let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].TOKEN_DIVIDEND_ADDRESS, signer);
                        let tx;
                        let t_amount = ethers.utils.parseUnits(samount, accStats.t_decimals);
                        tx = await divContract.stake(t_amount, { 'from': address });

                        toast.loading('Waiting for confirmation..');

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "stake": false });
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "stake": false });
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "stake": false });
                                }
                            }
                        }, 5000);
                    }
                    catch (err) {
                        toast.dismiss();
                        toast.error(err.reason ? err.reason : err.message);
                        setLoading({ ...loading, "stake": false });
                    }
                }
                else {
                    toast.dismiss();
                    toast.error('please enter valid amount.');
                    setLoading({ ...loading, "stake": false });
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "stake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "stake": false });
        }
    }

    const handleApprove = async () => {
        setLoading({ ...loading, "stake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS, signer);
                    let tx;
                    let t_amount = ethers.utils.parseUnits(samount, accStats.t_decimals);
                    tx = await tokenContract.approve(contract[DEFAULT_CHAIN].TOKEN_DIVIDEND_ADDRESS, t_amount, { 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "stake": false });
                }

            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "stake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "stake": false });
        }
    }

    const handleUnstake = async () => {
        setLoading({ ...loading, "unstake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                const patt = /^\d+\.{0,1}\d{0,4}$/;
                if (wamount && patt.test(wamount)) {
                    try {
                        let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].TOKEN_DIVIDEND_ADDRESS, signer);
                        let tx;
                        let t_amount = ethers.utils.parseUnits(wamount, accStats.t_decimals);
                        tx = await divContract.unstake(t_amount, { 'from': address });

                        toast.loading('Waiting for confirmation..');

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('success ! your last transaction is success');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "unstake": false });
                                }
                                else if (response.status === false) {
                                    toast.dismiss();
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "unstake": false });
                                }
                                else {
                                    toast.dismiss();
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading({ ...loading, "unstake": false });
                                }
                            }
                        }, 5000);
                    }
                    catch (err) {
                        toast.dismiss();
                        toast.error(err.reason ? err.reason : err.message);
                        setLoading({ ...loading, "unstake": false });
                    }
                }
                else {
                    toast.dismiss();
                    toast.error('please enter valid amount.');
                    setLoading({ ...loading, "unstake": false });
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "unstake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "unstake": false });
        }
    }





    return (
        <>
            <section className='banner-section'>
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-lg-7'>
                            <h2 className='title text-center'>Tradix Token Rewards</h2>
                            <p className='sub-title text-center'>Dedicated to give rewards to holders.</p>

                            <div className='row d-flex justify-content-sm-center'>
                                <div className='col-12 col-sm-8 col-md-12 col-xl-8 ' style={{ "fontSize": "13px" }}>
                                    <div className='card info-card mt-4 mb-4'>
                                        <div className='card-body text-white'>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your Token Balance : </span>
                                                <span> {accStats.balanceOf ? formatPrice(accStats.balanceOf) : 0} TX</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your Total Staked : </span>
                                                <span> {accStats.amount ? formatPrice(accStats.amount) : 0} TX</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Total Reward Distributed : </span>
                                                <span> $ {formatPrice(stats.totalRewardDistributed)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your Pending Reward : </span>
                                                <span> $ {formatPrice((parseFloat(accStats.getUnpaidEarning)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Total Reward Realised : </span>
                                                <span>$ {formatPrice((parseFloat(accStats.totalRealised) - parseFloat(accStats.reserved)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-5'>
                            <div className='card info-card'>
                                <div className='card-body'>
                                    <div className='mb-4'>
                                        <label for="exampleFormControlInput1" className="form-label">Stake Tokens</label>
                                        <div className="input-group">
                                            <input type="text" value={samount} className="form-control" onChange={(e) => handleChangeAmount(e, "stake")} placeholder="Enter token amount ..." />
                                        </div>
                                        <span className='text-danger'>{error.stake}</span>
                                        <div className='d-flex justify-content-center'>
                                            {parseFloat(accStats.allowance) >= parseFloat(samount) ?
                                                (<button disabled={loading.stake} className='btn btn-primary w-100 mt-2' type='button' onClick={() => handleStake()} >
                                                    {loading.stake ? 'loading...' : 'Stake'}
                                                </button>) :
                                                (<button disabled={loading.stake} className='btn btn-primary w-100 mt-2' type='button' onClick={() => handleApprove()} >
                                                    {loading.stake ? 'loading...' : 'Approve'}
                                                </button>)
                                            }
                                        </div>

                                    </div>

                                    <div className='mb-4'>
                                        <label for="exampleFormControlInput1" className="form-label">Unstake Tokens</label>
                                        <div className="input-group">
                                            <input type="text" value={wamount} className="form-control" onChange={(e) => handleChangeAmount(e, "unstake")} placeholder="Enter token amount ..." />
                                        </div>
                                        <span className='text-danger'>{error.unstake}</span>
                                        <div className='d-flex justify-content-center'>
                                            <button disabled={loading.unstake} className='btn btn-primary w-100 mt-2' type='button' onClick={() => handleUnstake()} >
                                                {loading.unstake ? 'loading...' : 'Unstake'}
                                            </button>
                                        </div>
                                    </div>

                                    <label for="exampleFormControlInput1" className="form-label">Claim Reward</label>
                                    <div className='d-flex justify-content-center'>
                                        <button disabled={loading.claim} className='btn btn-primary w-100' type='button' onClick={() => handleClaim()} >
                                            {loading.claim ? 'loading...' : 'Claim'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
