import React, { useState, useRef, useEffect } from 'react';
import { contract, DEFAULT_CHAIN, formatPrice, URI_PREFIX } from '../hooks/constant';
import { useAccNftStats, useCommonNftStats } from '../stats/useStats';
import { toast } from 'react-toastify';
import { getAllNFTDetails, getContract } from '../hooks/contractHelper';
import dividendAbi from '../json/nftdividend.json';
import nftAbi from '../json/nft.json';
import { getWeb3 } from '../hooks/connectors';
import Select, { components } from 'react-select';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { useAccount, useNetwork } from 'wagmi';



export default function Nft() {
    const [updater, setUpdater] = useState(1);
    const accStats = useAccNftStats(updater);
    const stats = useCommonNftStats(updater);
    const { address } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [loading, setLoading] = useState({
        stake: false,
        unstake: false,
        claim: false
    });
    const [walletNFTs, setWalletNFTs] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const [unstakeId, setUnstakeId] = useState(0);
    const count = useRef(0);
    const { Option, SingleValue } = components;
    let web3 = getWeb3(DEFAULT_CHAIN);


    useEffect(() => {
        async function fetch() {
            if (address && chain.id === DEFAULT_CHAIN) {
                // staticStart()

                if (count.current === 0) {
                    getAllNFTDetails(address, DEFAULT_CHAIN).then(async (response) => {
                        console.log(response)
                        if (response.length > 0) {
                            setSelectedId((response[0].id && response[0].id.tokenId) ? web3.utils.hexToNumberString(response[0].id.tokenId) : 0)
                        }
                        setWalletNFTs(response);
                    });
                }

                count.current = count.current + 1;
            }
        }
        fetch();
        // eslint-disable-next-line
    }, [address]);

    const IconOption = (props) => {
        let imageIpfs = `${URI_PREFIX}QmYywwnFjmWwz5kLTFveWEWifpTSjjEBbs9FGiWuajDPaz`;
        return (
            <Option {...props}>
                <img alt="nft-img" src={imageIpfs} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                {'TX Founders'}  #{(props.data.id && props.data.id.tokenId) ? web3.utils.hexToNumberString(props.data.id.tokenId) : ' - '}
            </Option>
        )
    }

    const IconSingleValue = (props) => {
        let imageIpfs = `${URI_PREFIX}QmYywwnFjmWwz5kLTFveWEWifpTSjjEBbs9FGiWuajDPaz`;
        return (
            <SingleValue {...props}>
                <img alt="nft-img" src={imageIpfs} style={{ height: '30px', width: '30px', borderRadius: '50%', marginRight: '10px' }} />
                {'TX Founders'} #{(props.data.id && props.data.id.tokenId) ? web3.utils.hexToNumberString(props.data.id.tokenId) : ' - '}
            </SingleValue>
        )
    }

    const customStyles = {
        option: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            // background: "#000"
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        }),
    }

    const handleNFTChange = async (e) => {
        let tId = e.id && e.id.tokenId ? web3.utils.hexToNumberString(e.id.tokenId) : ''
        setSelectedId(tId)
    }

    const handleUnNFTChange = async (e) => {
        let tId = e.id && e.id.tokenId ? web3.utils.hexToNumberString(e.id.tokenId) : ''
        setUnstakeId(tId)
    }

    const handleStake = async () => {
        setLoading({ ...loading, "stake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {

                try {
                    let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS, signer);
                    let tx;
                    tx = await divContract.stake(selectedId, { 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                                count.current = 0;
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                                count.current = 0;
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                                count.current = 0;
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "stake": false });
                }

            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "stake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "stake": false });
        }
    }

    const handleApprove = async () => {
        setLoading({ ...loading, "stake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    let tokenContract = getContract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS, signer);
                    let tx;
                    tx = await tokenContract.setApprovalForAll(contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS, true, { 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "stake": false });
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "stake": false });
                }

            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "stake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "stake": false });
        }
    }

    const handleUnStake = async () => {
        setLoading({ ...loading, "unstake": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {

                try {
                    let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS, signer);
                    let tx;
                    tx = await divContract.unstake(unstakeId, { 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "unstake": false });
                                count.current = 0;
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "unstake": false });
                                count.current = 0;
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "unstake": false });
                                count.current = 0;
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "unstake": false });
                }

            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "unstake": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "unstake": false });
        }
    }


    const handleClaim = async () => {
        setLoading({ ...loading, "claim": true });
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {
                    let divContract = getContract(dividendAbi, contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS, signer);
                    let tx;
                    tx = await divContract.claim({ 'from': address });

                    toast.loading('Waiting for confirmation..');

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('success ! your last transaction is success');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                            else if (response.status === false) {
                                toast.dismiss();
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                            else {
                                toast.dismiss();
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setLoading({ ...loading, "claim": false });
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    toast.dismiss();
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading({ ...loading, "claim": false });
                }
            }
            else {
                toast.dismiss();
                toast.error('Please connect to mainnet chain!!');
                setLoading({ ...loading, "claim": false });
            }
        }
        else {
            toast.dismiss();
            toast.error('Please connect wallet!!');
            setLoading({ ...loading, "claim": false });
        }
    }


    return (
        <>
            <section className='banner-section'>
                <div className="container-fluid">
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-lg-7'>
                            <h2 className='title text-center'>Tradix NFT Rewards</h2>
                            <p className='sub-title text-center'>Dedicated to give rewards to holders.</p>

                            <div className='row d-flex justify-content-sm-center'>
                                <div className='col-12 col-sm-8 col-md-12 col-xl-8 ' style={{ "fontSize": "13px" }}>
                                    <div className='card info-card mt-4 mb-4'>
                                        <div className='card-body text-white'>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your NFTs Balance : </span>
                                                <span> {accStats.balanceOf ? formatPrice(accStats.balanceOf) : 0} {stats.nftSymbol ? stats.nftSymbol : '' }</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your Total Staked NFTs : </span>
                                                <span> {accStats.subtotal ? formatPrice(accStats.subtotal) : 0} {stats.nftSymbol ? stats.nftSymbol : ''  }</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>TOTAL REWARD DISTRIBUTED : </span>
                                                <span> $ {formatPrice(stats.totalRewardDistributed)}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>Your PENDING REWARD : </span>
                                                <span> $ {formatPrice((parseFloat(accStats.getUnpaidEarning)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                            <div className='d-flex justify-content-between mb-2 mt-3'>
                                                <span>TOTAL REWARD REALISED : </span>
                                                <span>$ {formatPrice((parseFloat(accStats.totalRealised) - parseFloat(accStats.reserved)) * parseFloat(stats.ethPrice))}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-5'>
                            <div className='card info-card'>
                                <div className='card-body'>
                                    <div className='mb-1'>
                                        <label for="exampleFormControlInput1" className="form-label">Stake Nft</label>

                                        <Select
                                            styles={customStyles}
                                            components={{ Option: IconOption, SingleValue: IconSingleValue }}
                                            options={walletNFTs}
                                            onChange={(e) => handleNFTChange(e)}
                                            class="form-control"
                                        />
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        {accStats.isApprovedForAll ?
                                            (<button className='btn btn-primary w-100 mt-1' type='button' loading={loading.stake} onClick={() => handleStake()}  >
                                                {loading.stake ? 'loading...' : 'Stake'}
                                            </button>)
                                            :
                                            (<button className='btn btn-primary w-100 mt-1' type='button' loading={loading.stake} onClick={() => handleApprove()}  >
                                                {loading.stake ? 'loading...' : 'Approve'}
                                            </button>)
                                        }

                                    </div>
                                    <div className='mt-3'>
                                        <label for="exampleFormControlInput1" className="form-label">Unstake Nft</label>

                                        <Select
                                            styles={customStyles}
                                            components={{ Option: IconOption, SingleValue: IconSingleValue }}
                                            options={accStats.stakedNFTData}
                                            onChange={(e) => handleUnNFTChange(e)}
                                            class="form-control"
                                        />
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <button className='btn btn-primary w-100 mt-1' loading={loading.unstake} type='button' onClick={() => handleUnStake()}  >
                                            {loading.unstake ? 'loading...' : 'Unstake'}
                                        </button>
                                    </div>
                                    <div className='mb-1 mt-3'>
                                        <label for="exampleFormControlInput1" className="form-label">Claim Reward</label>
                                    </div>
                                    <button disabled={loading.claim} className='btn btn-primary w-100' type='button' onClick={() => handleClaim()} >
                                        {loading.claim ? 'loading...' : 'Claim'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
