
export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  1: {
    TOKEN_DIVIDEND_ADDRESS: "0x767047703303278e0b36E796369e27A772aD56d9",
    NFT_DIVIDEND_ADDRESS: "0xa23F2D7c3defAb98A5Ce878de461f2E79f5e9C6F",
    TOKEN_ADDRESS: "0x8954D907520532c1f0d89d42569232Fd0f995Fdf",
    NFT_ADDRESS: "0x4De4410d84abd717eE25Bf0a345Ee9f7B13E54f7",
    MULTICALL_ADDRESS: "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796"
  },
  'default': {
    TOKEN_DIVIDEND_ADDRESS: "0x767047703303278e0b36E796369e27A772aD56d9",
    NFT_DIVIDEND_ADDRESS: "0xa23F2D7c3defAb98A5Ce878de461f2E79f5e9C6F",
    TOKEN_ADDRESS: "0x8954D907520532c1f0d89d42569232Fd0f995Fdf",
    NFT_ADDRESS: "0x4De4410d84abd717eE25Bf0a345Ee9f7B13E54f7",
    MULTICALL_ADDRESS: "0x5e227AD1969Ea493B43F840cfF78d08a6fc17796"
  }

}

export const MAINNET_RPC = "https://ethereum.publicnode.com";
export const DEFAULT_CHAIN = 1;
export const ETH_LINK_ADDRESS = "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419";
export const ALCHEMY_API_KEY = 'Q6p_ar0pOwIOrU469BlY0rIwsr3Pe2M2';
export const ALCHEMY_BASE_URL = `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}/getNFTs/`;
export const URI_PREFIX = 'https://ipfs.moralis.io:2053/ipfs/';
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604"



export const formatPrice = (num) => {
  try {
    return new Intl.NumberFormat('en-US' , {maximumSignificantDigits : 12}).format(num);
  }
  catch (err) {
    console.log(err.message);
    return 0;
  }
}
