export const supportNetwork = {
  1: {
    name: "ETH Mainnet Network",
    chainId: 1,
    rpc: "https://ethereum.publicnode.com",
    symbol: "ETH",
    buynowurl : "https://app.uniswap.org/#/swap?outputCurrency=0x8954D907520532c1f0d89d42569232Fd0f995Fdf"
  },
  default: {
    name: "ETH Mainnet Network",
    chainId: 1,
    rpc: "https://ethereum.publicnode.com",
    symbol: "ETH",
    buynowurl : "https://app.uniswap.org/#/swap?outputCurrency=0x8954D907520532c1f0d89d42569232Fd0f995Fdf"
  }
};

export const RPC_URLS = {
  1: "https://ethereum.publicnode.com",
};
