import { ethers } from "ethers";
import { getWeb3 } from "./connectors";
import { ALCHEMY_BASE_URL, contract } from "./constant";
import multiCallAbi from '../json/multicall.json';
import tokenAbi from '../json/token.json';
import nftAbi from '../json/nft.json';
import dividendAbi from '../json/dividend.json';
import nftdividendAbi from '../json/nftdividend.json';
import axios from "axios";




export const getContract = (abi, address, library) => {
  try {
    return new ethers.Contract(address, abi, library)
  }
  catch {
    return false;
  }
}


export const MulticallContractWeb3 = async (calls = [],chainId = 'default') => {
  let web3 = getWeb3(chainId);
  let multicallAddress = contract[chainId] ? contract[chainId].MULTICALL_ADDRESS : contract['default'].MULTICALL_ADDRESS;
  const mc = new web3.eth.Contract(multiCallAbi, multicallAddress);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}

export const getTokenContract = (chainId = 'default') => {
  let web3 = getWeb3(chainId);
  let address = contract[chainId] ? contract[chainId].TOKEN_ADDRESS : contract['default'].TOKEN_ADDRESS;
  return new web3.eth.Contract(tokenAbi, address);

}

export const getNftContract = (chainId = 'default') => {
  let web3 = getWeb3(chainId);
  let address = contract[chainId] ? contract[chainId].NFT_ADDRESS : contract['default'].NFT_ADDRESS;
  return new web3.eth.Contract(nftAbi, address);

}

export const getDividendTokenContract = (chainId = 'default') => {
  let web3 = getWeb3(chainId);
  let address = contract[chainId] ? contract[chainId].TOKEN_DIVIDEND_ADDRESS : contract['default'].TOKEN_DIVIDEND_ADDRESS;
  return new web3.eth.Contract(dividendAbi, address);
}

export const getDividendNftContract = (chainId = 'default') => {
  let web3 = getWeb3(chainId);
  let address = contract[chainId] ? contract[chainId].NFT_DIVIDEND_ADDRESS : contract['default'].NFT_DIVIDEND_ADDRESS;
  return new web3.eth.Contract(nftdividendAbi, address);
}



export const getCustomTokenContract = (address,chainId = 'default') => {
  console.log(chainId);
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(tokenAbi, address);

}


export const getAllNFTDetails = async (walletAddress = '' , chainId) => {
  const DEFAULT_CHAIN = chainId ? chainId : 'default'; 
  if (walletAddress) {
    let userNftData = [];
    let pageKey = '';

    async function* fetchData() {
      try {
        while (true) {
          const fetchURL = !contract[DEFAULT_CHAIN].NFT_ADDRESS
            ? `${ALCHEMY_BASE_URL}?owner=${walletAddress}&withMetadata=true`
            : pageKey ? `${ALCHEMY_BASE_URL}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&pageKey=${pageKey}&withMetadata=true` : `${ALCHEMY_BASE_URL}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&withMetadata=true`;


          const config = {
            method: 'get',
            url: fetchURL,

          };
          let data = await axios(config);
          if (data.data && data.data !== undefined && data.data.ownedNfts.length > 0) {
            yield data.data;
          }
          if (data.data.pageKey && data.data.pageKey !== '') {
            pageKey = data.data.pageKey
          }
          else {
            break;
          }

        }
      } finally {

      }

    }

    for await (const nftData of fetchData()) {
      nftData.ownedNfts.map((value) => {
        userNftData.push(value);
        return true;
      })
    }

    return userNftData;
  }
  else {
    return false;
  }

}

export const getStakeNFTDetails = async (stakeTokenIds = [] ,chainId) => {
  const DEFAULT_CHAIN = chainId ? chainId : 'default'; 
  if (stakeTokenIds.length > 0) {
    let web3 = getWeb3();
    let userNftData = [];
    let pageKey = '';

    async function* fetchData() {
      try {
        while (true) {
          const fetchURL = !contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS
            ? `${ALCHEMY_BASE_URL}?owner=${contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS}&withMetadata=true`
            : pageKey ? `${ALCHEMY_BASE_URL}?owner=${contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&pageKey=${pageKey}&withMetadata=true` : `${ALCHEMY_BASE_URL}?owner=${contract[DEFAULT_CHAIN].NFT_DIVIDEND_ADDRESS}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&withMetadata=true`;


          const config = {
            method: 'get',
            url: fetchURL,

          };
          let data = await axios(config);
          if (data.data && data.data !== undefined && data.data.ownedNfts.length > 0) {
            yield data.data;
          }
          if (data.data.pageKey && data.data.pageKey !== '') {
            pageKey = data.data.pageKey
          }
          else {
            break;
          }

        }
      } finally {

      }

    }

    for await (const nftData of fetchData()) {
      nftData.ownedNfts.map((value) => {
        if (value.id.tokenId !== undefined || value.id.tokenId !== '') {
          let tokenId = web3.utils.hexToNumberString(value.id.tokenId)
          if (stakeTokenIds.includes(tokenId)) {
            userNftData.push(value);
          }
        }
        return true;
      })
    }

    return userNftData;
  }
  else {
    return false;
  }
}



