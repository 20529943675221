import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Token from './pages/Token';
import Nft from './pages/Nft';

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
        <ToastContainer pauseOnFocusLoss={false} />
        <Switch>
          <Route exact path="/">
            <Token />
          </Route>
          <Route exact path="/nft">
            <Nft />
          </Route>
        </Switch>
        <Footer/>
      </Router>

    </div>
  );
}

export default App;
