import React,{useMemo } from 'react';
import Connect from './Connect';
import logoImg from '../images/logo.png';
import { Link , useLocation } from 'react-router-dom';
import { supportNetwork } from '../hooks/network';
import { DEFAULT_CHAIN } from '../hooks/constant';

export default function Header() {

    const router = useRouter();
    
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-dark bg-dark">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={logoImg} alt="ether logo" className='brand-logo' />
                </Link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <Link to="/" class={`nav-link ${router.pathname === "/" ? "active" : ""}`} aria-current="page">
                                Token
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link to="/nft" class={`nav-link ${router.pathname === "/nft" ? "active" : ""}`} aria-current="page">
                                NFT
                            </Link>
                        </li>
                        <li class="nav-item">
                            <a href={supportNetwork[DEFAULT_CHAIN] && supportNetwork[DEFAULT_CHAIN].buynowurl ? supportNetwork[DEFAULT_CHAIN].buynowurl : supportNetwork['default'].buynowurl} rel="noreferrer" target="_blank" className="btn btn-primary ms-auto" >
                                Buy Now
                            </a>
                        </li>
                        <li class="nav-item">
                            <Connect />
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    )
}


export function useRouter() {
    const location = useLocation();

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            push: location.push,
            replace: location.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
        
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            location,

        };
    }, [location]);
}
