import React from 'react'

export default function Footer() {
    return (
        <footer className='footer'>
            <p className='text-center'>
                © Tradix 2023 All Rights Reserved.
            </p>
        </footer>
    )
}
